var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('the-breadcrumbs'),_c('page-content',{attrs:{"page-title":"Alle Ihre Artikel"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$store.getters['auth/isSuperAdmin'])?_c('region-chooser',{on:{"region:chosen":_vm.handleRegionChange}}):_vm._e()]},proxy:true}])},[_c('el-input',{staticStyle:{"max-width":"500px"},attrs:{"placeholder":"Durchsuchen…","clearable":""},model:{value:(_vm.computedSearchQuery),callback:function ($$v) {_vm.computedSearchQuery=$$v},expression:"computedSearchQuery"}},[_c('i',{staticClass:"el-icon-search el-input__icon",attrs:{"slot":"prefix"},slot:"prefix"})]),_c('data-loader',{ref:"dataloader",attrs:{"endpoint":("/admin/articles?page=" + _vm.currentPage + "&query=" + _vm.remoteSearchQuery + "&region_id=" + (_vm.$store.state.auth.chosenRegion))},scopedSlots:_vm._u([{key:"loaded",fn:function(ref){
var data = ref.data;
return [_c('el-table',{staticStyle:{"width":"100%"},attrs:{"empty-text":"Keine Ergebnisse","stripe":"","data":data.articles}},[_c('el-table-column',{attrs:{"prop":"title","label":"Titel"}}),_c('el-table-column',{attrs:{"label":"Veröffentlicht"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.published)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" Ja ")]):_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("Nein")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Geteilt"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.original)?[(scope.row.shared)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" Ja ")]):_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("Nein")])]:_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Aktualisiert"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.original)?[(
                    _vm.differenceInCalendarMonths(
                      new Date(),
                      _vm.parseISO(scope.row.updated_at)
                    ) < 6
                  )?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(scope.row.updated_at), "dd.LL.yyyy"))+" ")]):(
                    _vm.differenceInCalendarMonths(
                      new Date(),
                      _vm.parseISO(scope.row.updated_at)
                    ) < 12
                  )?_c('el-tag',{attrs:{"size":"small","type":"warning"}},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(scope.row.updated_at), "dd.LL.yyyy"))+" ")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(scope.row.updated_at), "dd.LL.yyyy"))+" ")])]:_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Ursprung"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.original)?[(scope.row.original.region_name === 'Musterregion')?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v(" Studio Nomai ")]):[_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v(" "+_vm._s(scope.row.original.region_name)+" ")])]]:_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Inhalt"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.row.original_newer && scope.row.differs_from_original
                )?_c('el-popover',{attrs:{"placement":"top","trigger":"hover"}},[_c('div',[_vm._v(" Ursprungsartikel ist neuer als Ihre Version ")]),_c('span',{attrs:{"slot":"reference"},on:{"click":function($event){return _vm.goToReview(scope.row.id)}},slot:"reference"},[_c('i',{staticClass:"fa-exclamation-triangle fal",staticStyle:{"color":"red"}})])]):(
                  !scope.row.original_newer && scope.row.differs_from_original
                )?_c('el-popover',{attrs:{"placement":"top","trigger":"hover"}},[_c('div',[_vm._v(" Ihre Version weicht vom Ursprungsartikel ab ")]),_c('span',{attrs:{"slot":"reference"},on:{"click":function($event){return _vm.goToReview(scope.row.id)}},slot:"reference"},[_c('i',{staticClass:"fa-code-branch fal",staticStyle:{"color":"orange"}})])]):(scope.row.original)?_c('el-popover',{attrs:{"placement":"top","trigger":"hover"}},[_c('div',[_vm._v(" Beide Versionen stimmen überein ")]),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"fa-check fal",staticStyle:{"color":"green"}})])]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Aktionen","width":"270"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"fal fa-edit","size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v(" Bearbeiten ")]),(scope.row.differs_from_original)?_c('el-button',{attrs:{"icon":"fal fa-code-branch","size":"mini"},on:{"click":function($event){return _vm.goToReview(scope.row.id)}}},[_vm._v(" Vergleichen ")]):_vm._e()]}}],null,true)})],1),_c('the-pagination',{attrs:{"total-count":data.total_count},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }