<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Alle Ihre Artikel">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
      </template>

      <el-input
        v-model="computedSearchQuery"
        placeholder="Durchsuchen…"
        clearable
        style="max-width: 500px;"
      >
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
      </el-input>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/articles?page=${currentPage}&query=${remoteSearchQuery}&region_id=${$store.state.auth.chosenRegion}`
        "
      >
        <template #loaded="{ data }">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.articles"
          >
            <el-table-column prop="title" label="Titel"></el-table-column>

            <el-table-column label="Veröffentlicht">
              <template #default="scope">
                <el-tag v-if="scope.row.published" size="small" type="success">
                  Ja
                </el-tag>
                <el-tag v-else size="small" type="info">Nein</el-tag>
              </template>
            </el-table-column>

            <el-table-column label="Geteilt">
              <template #default="scope">
                <template v-if="!scope.row.original"
                  ><el-tag v-if="scope.row.shared" size="small" type="success">
                    Ja
                  </el-tag>
                  <el-tag v-else size="small" type="info"
                    >Nein</el-tag
                  ></template
                >
              </template>
            </el-table-column>

            <el-table-column label="Aktualisiert">
              <template #default="scope">
                <template v-if="!scope.row.original">
                  <el-tag
                    v-if="
                      differenceInCalendarMonths(
                        new Date(),
                        parseISO(scope.row.updated_at)
                      ) < 6
                    "
                    size="small"
                    type="success"
                  >
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                  <el-tag
                    v-else-if="
                      differenceInCalendarMonths(
                        new Date(),
                        parseISO(scope.row.updated_at)
                      ) < 12
                    "
                    size="small"
                    type="warning"
                  >
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                  <el-tag v-else size="small" type="danger">
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Ursprung">
              <template #default="scope">
                <template v-if="scope.row.original">
                  <el-tag
                    v-if="scope.row.original.region_name === 'Musterregion'"
                    size="small"
                    type="info"
                  >
                    Studio Nomai
                  </el-tag>
                  <template v-else>
                    <el-tag size="small" type="info">
                      {{ scope.row.original.region_name }}
                    </el-tag>
                  </template>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Inhalt">
              <template #default="scope">
                <el-popover
                  v-if="
                    scope.row.original_newer && scope.row.differs_from_original
                  "
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Ursprungsartikel ist neuer als Ihre Version
                  </div>
                  <span slot="reference" @click="goToReview(scope.row.id)">
                    <i
                      class="fa-exclamation-triangle fal"
                      style="color: red;"
                    ></i>
                  </span>
                </el-popover>
                <el-popover
                  v-else-if="
                    !scope.row.original_newer && scope.row.differs_from_original
                  "
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Ihre Version weicht vom Ursprungsartikel ab
                  </div>
                  <span slot="reference" @click="goToReview(scope.row.id)">
                    <i class="fa-code-branch fal" style="color: orange;"></i>
                  </span>
                </el-popover>
                <el-popover
                  v-else-if="scope.row.original"
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Beide Versionen stimmen überein
                  </div>
                  <span slot="reference">
                    <i class="fa-check fal" style="color: green;"></i>
                  </span>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column label="Aktionen" width="270">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <el-button
                  v-if="scope.row.differs_from_original"
                  icon="fal fa-code-branch"
                  size="mini"
                  @click="goToReview(scope.row.id)"
                >
                  Vergleichen
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import { format, parseISO, differenceInCalendarMonths } from "date-fns"
import * as log from "loglevel"
import { debounce } from "lodash-es"

import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"

export default {
  metaInfo: {
    title: "Alle Ihre Artikel"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    ThePagination
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      format,
      parseISO,
      differenceInCalendarMonths,
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || ""
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    }
  },
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          query: "",
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    goToReview(id) {
      this.$router.push({
        name: "ReviewArticleOriginalChangesPage",
        params: { id: id }
      })
    },
    handleEdit(article) {
      this.$router.push({
        name: "EditArticlePage",
        query: {
          article_collection_name: article.collection_name,
          article_collection_id: article.collection_id,
          redirect: this.$route.query.redirect || this.$route.fullPath
        },
        params: {
          id: article.id
        }
      })
    }
  }
}
</script>
